.navButton {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: var(--borderRadiusLarge);
    padding-left: 0;
    background-size: cover;

}


.fader {
    backdrop-filter: blur(3px);
    background-image: linear-gradient(to right, rgba(91, 138, 168, 1), rgba(91, 138, 168, 0.9));
    opacity: 1;
    transition: opacity 0.5s linear;

    border-radius: var(--borderRadiusLarge);
    color: var(--fg2);
    padding: var(--gap);

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    flex-grow: 1;
    gap: var(--gap);
}

.fader:hover {
    background-color: var(--bg3);
    background-image: none;
}

a {
    text-decoration: none;
}

.left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    flex-grow: 1;
}

.right {}